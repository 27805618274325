var exports = {};

exports = function (hljs) {
  var GO_KEYWORDS = {
    keyword: "break default func interface select case map struct chan else goto package switch " + "const fallthrough if range type continue for import return var go defer " + "bool byte complex64 complex128 float32 float64 int8 int16 int32 int64 string uint8 " + "uint16 uint32 uint64 int uint uintptr rune",
    literal: "true false iota nil",
    built_in: "append cap close complex copy imag len make new panic print println real recover delete"
  };
  return {
    aliases: ["golang"],
    keywords: GO_KEYWORDS,
    illegal: "</",
    contains: [hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, {
      className: "string",
      variants: [hljs.QUOTE_STRING_MODE, {
        begin: "'",
        end: "[^\\\\]'"
      }, {
        begin: "`",
        end: "`"
      }]
    }, {
      className: "number",
      variants: [{
        begin: hljs.C_NUMBER_RE + "[i]",
        relevance: 1
      }, hljs.C_NUMBER_MODE]
    }, {
      begin: /:=/ // relevance booster

    }, {
      className: "function",
      beginKeywords: "func",
      end: /\s*\{/,
      excludeEnd: true,
      contains: [hljs.TITLE_MODE, {
        className: "params",
        begin: /\(/,
        end: /\)/,
        keywords: GO_KEYWORDS,
        illegal: /["']/
      }]
    }]
  };
};

export default exports;